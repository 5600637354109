export const environment = {
  production: false,
  allowInnoLogin: true,
  apiUrl: 'https://armsapidev.innovativeideas.com.br',
  aadRedirectUri: 'https://armsdev.innovativeideas.com.br/login',
  aadClientId: '0c859ef7-4e83-4205-bb35-2baf529861fb',
  aadTenantId: '1ac1fe3c-cc7f-4b88-b2b4-9dcb9f571402',
  innoAadClientId: '3b5d9179-4820-4518-a175-a4ba0ca64dac',
  innoAadTenantId: 'ec230af7-d9c4-4e7d-bd31-075af4f3d29b',
  amplitudeApiKey: '67843e8f8a9e622cc9e593c8c5d3012e',
  fromEmail: 'ir@absinv.com',
  fromName: 'ABS Investor Relations',
};
