import * as amplitude from '@amplitude/analytics-browser';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AmplitudeService {
  /**
   * Setup the Amplitude SDK.
   *
   * @param apiKey The Amplitude API KEY.
   */
  setup(apiKey: string) {
    amplitude.init(apiKey, {
      defaultTracking: false,
      minIdLength: 3, // default to 5, which won't work with user ids like "SMW"
    });
  }

  /**
   * Identify the user in the Amplitude SDK.
   *
   * @param userProperties The properties to set about the user.
   */
  identify(userId: string, userProperties: Record<string, string | number>) {
    amplitude.setUserId(userId);

    const identifyEvent = new amplitude.Identify();

    Object.entries(userProperties).forEach(([key, value]) => {
      identifyEvent.set(key, value);
    });

    amplitude.identify(identifyEvent);
  }

  /**
   * Clear the user information in the Amplitude SDK.
   */
  logout() {
    amplitude.reset();
  }

  /**
   * Log an event with Amplitude SDK.
   *
   * @param eventName The event identification.
   * @param eventProperties Optional properties to attach to the event.
   */
  track(eventName: string, eventProperties?: Record<string, unknown>) {
    amplitude.track(eventName, eventProperties);
  }
}
