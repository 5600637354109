import { __awaiter, __generator } from "tslib";
import { getGlobalScope } from '@amplitude/analytics-client-common';
export var networkConnectivityCheckerPlugin = function () {
  var name = '@amplitude/plugin-network-checker-browser';
  var type = 'before';
  var globalScope = getGlobalScope();
  var eventListeners = [];
  var addNetworkListener = function (type, handler) {
    if (globalScope) {
      globalScope.addEventListener(type, handler);
      eventListeners.push({
        type: type,
        handler: handler
      });
    }
  };
  var removeNetworkListeners = function () {
    eventListeners.forEach(function (_a) {
      var type = _a.type,
        handler = _a.handler;
      if (globalScope) {
        globalScope.removeEventListener(type, handler);
      }
    });
    eventListeners = [];
  };
  var setup = function (config, amplitude) {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        if (typeof navigator === 'undefined') {
          config.loggerProvider.debug('Network connectivity checker plugin is disabled because navigator is not available.');
          config.offline = false;
          return [2 /*return*/];
        }
        config.offline = !navigator.onLine;
        addNetworkListener('online', function () {
          config.loggerProvider.debug('Network connectivity changed to online.');
          config.offline = false;
          // Flush immediately will cause ERR_NETWORK_CHANGED
          setTimeout(function () {
            amplitude.flush();
          }, config.flushIntervalMillis);
        });
        addNetworkListener('offline', function () {
          config.loggerProvider.debug('Network connectivity changed to offline.');
          config.offline = true;
        });
        return [2 /*return*/];
      });
    });
  };
  var teardown = function () {
    return __awaiter(void 0, void 0, void 0, function () {
      return __generator(this, function (_a) {
        removeNetworkListeners();
        return [2 /*return*/];
      });
    });
  };
  return {
    name: name,
    type: type,
    setup: setup,
    teardown: teardown
  };
};
